import { types, getRoot } from 'mobx-state-tree';
import {
  fetchAccount,
  fetchInvoices,
  fetchCouponData,
  fetchCouponShopifyData,
} from 'api';

import { Router } from 'server/next-routes';
import { Account } from './models/Account';
import { Invoices } from './models/Invoices';
import CouponData from './models/CouponData';

export const AccountStore = types
  .model('AccountStore', {
    accounts: types.map(Account),
    invoices: types.map(Invoices),
    referralCoupon: types.maybeNull(CouponData),
    referralShopify: types.maybeNull(CouponData),
  })
  .actions(self => ({
    deleteAccount(country) {
      self.accounts.delete(country);
    },
    setAccount(country, data) {
      self.accounts.set(country, {
        ...self.accounts.get(country),
        ...data,
      });
    },
    deleteInvoices(country) {
      self.invoices.delete(country);
    },
    setInvoices(country, data) {
      self.invoices.set(country, {
        ...data,
      });
    },
    setReferralCoupon(referralCouponData) {
      self.referralCoupon = referralCouponData;
    },
    setReferralShopify(referralShopifyData) {
      self.referralShopify = referralShopifyData;
    },
    async fetchAccount(country) {
      self.setAccount(country, {
        loading: true,
      });
      const { accessToken } = getRoot(self).authStore;
      try {
        const account = await fetchAccount(accessToken, country);
        getRoot(self).authStore.setAccessToken();

        let referralCouponData = null;
        let referralCouponShopfyData = null;
        const accountReferralCode = account
          && account.basicInfo
          && account.basicInfo.Referral_Code__c;
        const accountReferralShopifyCode = account
          && account.basicInfo
          && account.basicInfo.ShopifyCustomerID__c;
        if (accountReferralCode) {
          try {
            referralCouponData = await fetchCouponData(accountReferralCode);
          } catch (err) {
            //
          }
        }

        if (accountReferralShopifyCode) {
          try {
            referralCouponShopfyData = await fetchCouponShopifyData(accessToken);
          } catch (err) {
            //
          }
        }

        getRoot(self).authStore.setAccessToken();
        self.setAccount(country, {
          accountData: account,
          loading: false,
        });
        if (referralCouponData) {
          self.setReferralCoupon(referralCouponData);
        }
        if (referralCouponShopfyData) {
          self.setReferralShopify(referralCouponShopfyData);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          self.setAccount(country, {
            loading: false,
            accountData: undefined,
          });
          getRoot(self).authStore.logout();
          if (country === 'ch') {
            Router.pushRoute('/ch/login');
            return;
          }
          Router.pushRoute('/login');
        }
      }
    },
    async fetchInvoices(country) {
      self.setInvoices(country, {
        ...self.invoices.get(country),
        loading: true,
      });
      const { accessToken } = getRoot(self).authStore;
      try {
        const invoices = await fetchInvoices(accessToken, country);
        self.setInvoices(country, {
          ...invoices,
          loading: false,
          loaded: true,
        });
      } catch (error) {
        self.setInvoices(country, {
          loading: false,
          loaded: false,
          invoices: [],
        });
      }
    },
  }))
  .views(self => ({
    get localAccount() {
      return self.accounts.get(getRoot(self).country);
    },
    get localInvoices() {
      return self.invoices.get(getRoot(self).country);
    },
    get name() {
      const account = self.localAccount;
      if (account && account.hasFetched) {
        return account.accountData.basicInfo.name;
      }
      return undefined;
    },
  }));
