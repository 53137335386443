import React, { Component } from 'react';
import { withTranslation, Trans } from 'server/i18n';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import PaymentMethodsList from 'components/common/payment-methods-list';
import IconCheckmark from 'components/icons/Checkmark';
import Spinner from 'components/common/spinner';
import ErrorOverlay from 'components/dashboard-membership/error-overlay';
import cx from 'classnames';
import { getCountryByValue } from 'constants';
import css from './style.css';
import { AddNewPaymentMethodLayout } from '../add-new-payment-layout';

const MODE = {
  CVV_VALID: 0,
  ADD_CC: 1,
};


@withTranslation('membership')
@inject('mobxStore')
@observer
class ImmediateRenewModal extends Component {
  @observable agreeToTerms = false;

  @observable isValid = false;

  @observable isValidating = false;

  @observable mode = MODE.CVV_VALID;

  @observable paymentCardCount = 0;

  @observable zouraHpmLoaded = false;

  @observable creditBalance = false;

  @observable paymentData = {
    paymentMethodId: null,
    paymentMethodIndex: null,
    cvv: '',
    cvvValid: false,
    isSepa: false,
  };

  @observable errorData = {
    error: null,
    errorMessage: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      activeTab: 'stripe',
    };
    this.paymentCardCount = this.props
      .mobxStore.accountStore.localAccount.sortedPaymentMethods
      .filter((card) => {
        if (card.expirationYear && card.expirationMonth) {
          return !PaymentMethodsList.isExpired(
            card.expirationYear,
            card.expirationMonth,
          );
        }
        return true;
      })
      .filter(PaymentMethodsList.uniqueCards).length;
    this.mode = this.paymentCardCount > 0 ? MODE.CVV_VALID : MODE.ADD_CC;
  }

  get submitDisabled() {
    if (this.mode === MODE.ADD_CC && this.state.activeTab === 'sepa' && this.isValidating && this.agreeToTerms && this.zouraHpmLoaded) {
      return 'disabled';
    }
    if (this.mode === MODE.ADD_CC) {
      return this.agreeToTerms && !this.zouraHpmLoaded ? '' : 'disabled';
    }
    if (this.mode === MODE.CVV_VALID && this.paymentData.isSepa && this.agreeToTerms) {
      return false;
    }
    if (this.mode === MODE.CVV_VALID) {
      return (this.agreeToTerms && this.paymentData.cvvValid) && !this.isValidating ? '' : 'disabled';
    }
    return true;
  }

  handleSetActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      isSubmitted: false,
    });
    this.isValidating = false;
  }

  setHpmState = (isLoaded) => {
    this.zouraHpmLoaded = isLoaded;
  };

  handleModalClose = () => {
    this.props.onClose();
  }

  handleCvvChange = (paymentMethodId, paymentMethodIndex, cvv, cvvValid, isSepa = false) => {
    this.paymentData = {
      paymentMethodId,
      paymentMethodIndex,
      cvv,
      cvvValid,
      isSepa,
    };
  };

  handleCCAdded = async (error = null, paymentMethodId) => {
    const {
      subscription: {
        invoices,
        reload,
        id,
      },
      mobxStore: {
        country,
        accountStore: {
          fetchInvoices,
          localAccount: {
            payInvoiceAndRenew,
          },
        },
      },
    } = this.props;

    if (error) {
      this.isValidating = false;
    } else {
      const [invoice] = invoices.filter(({ balance }) => balance > 0); // first unpaid invoice
      try {
        await payInvoiceAndRenew(invoice, paymentMethodId);
        await fetchInvoices(country);
        await reload(id);
      } catch (e) {
        this.onError(e.message);
        return;
      }
      this.handleModalClose();
      this.isValidating = false;
    }
  }

  handleSubmit = async () => {
    const {
      subscription: {
        id,
        invoices,
        reload,
      },
      mobxStore: {
        accountStore: {
          fetchInvoices,
          localAccount: {
            accountData,
            postPaymentMethodVerify,
            postPaymentMethodDefault,
            payInvoiceAndRenew,
          },
        },
      },
    } = this.props;
    this.isValidating = true;
    const {
      basicInfo: {
        OriginCountry__c: country,
      },
    } = accountData;

    const { currencyCode } = getCountryByValue(country);

    if (this.mode === MODE.ADD_CC) {
      this.setState({ isSubmitted: true });
      Z.submit();
    } else if (this.mode === MODE.CVV_VALID) {
      // verifying
      if (!this.paymentData.isSepa) {
        const { success, message } = await postPaymentMethodVerify(
          this.paymentData.paymentMethodId,
          currencyCode,
          this.paymentData.cvv,
        );
        if (!success) {
          this.onError(message);
          return;
        }
      }

      const activeTab = country === 'be' && this.paymentData.isSepa ? 'sepa' : 'stripe';
      // set used credit card as default
      await postPaymentMethodDefault(
        this.paymentData.paymentMethodId,
        this.paymentData.paymentMethodIndex,
        activeTab,
      );

      const [invoice] = invoices.filter(({ balance }) => balance > 0); // first unpaid invoice
      try {
        await payInvoiceAndRenew(invoice, this.paymentData.paymentMethodId, this.creditBalance);
        await fetchInvoices(country);
        await reload(id);
      } catch (e) {
        this.onError(e.message);
        return;
      }

      this.isValidating = false;
      this.handleModalClose();
      // this.props.displaySuccess(); // TODO uncomment when done
    }
  }

  handleAgreeToTermsClick = (event) => {
    this.agreeToTerms = !this.agreeToTerms;
    event.preventDefault();
  }

  handleChangeSubmitStatus = (status) => {
    this.setState({ isSubmitted: status });
  }

  renderPaymentMethodWidget = (mode, country) => {
    switch (mode) {
      case MODE.CVV_VALID:
        return (
          <PaymentMethodsList
            action={this.handleCvvChange}
            hideExpired
          />
        );
      case MODE.ADD_CC:
        return (
          <AddNewPaymentMethodLayout
            zouraHpmLoaded={this.zouraHpmLoaded}
            country={country}
            handleSetActiveTab={this.handleSetActiveTab}
            activeTab={this.state.activeTab}
            handleChangeSubmitStatus={this.handleChangeSubmitStatus}
            isSubmitted={this.state.isSubmitted}
            finished={this.handleCCAdded}
            onError={this.onError}
            isLoaded={this.setHpmState}
            makeDefault
          />
        );
      default:
        return <h2>Unknown mode.</h2>;
    }
  }

  onError = (error) => {
    const { t } = this.props;
    if (error.includes('Your card was declined.')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.credit-card-declined'),
      };
      return;
    }
    if (error.includes("Your card's security code is incorrect.")) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.credit-card-security'),
      };
      return;
    }
    if (error.includes('expired_card')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.your-credit-card-has-been-declined'),
      };
      return;
    }
    if (error.includes('incorrect_cvc')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.cvv-incorrect'),
      };
      return;
    }
    if (error.includes('Required Field Not Completed')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.required-field-not-completed'),
      };
      return;
    }
    if (error.includes('Erforderlich')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.required-field-not-completed'),
      };
      return;
    }
    if (error.includes('Kartennummer ist ungültig')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.invalid-card-number'),
      };
      return;
    }
    if (error.includes('Invalid Card Number')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.invalid-card-number'),
      };
      return;
    }
    if (error.includes('Expiration date must be a future date')) {
      this.errorData = {
        error: true,
        message: t('membership:memberships.errors.must-be-a-future-date'),
      };
      return;
    }
    this.errorData = {
      error: true,
      message: t('membership:memberships.errors.something-went-wrong'),
    };
  }

  onErrorDismiss = () => {
    this.errorData = {
      error: false,
      message: '',
    };
    this.isValidating = false;
  }

  toggleMode = () => {
    this.agreeToTerms = false;
    this.zouraHpmLoaded = false;

    if (this.mode === MODE.ADD_CC) {
      this.mode = MODE.CVV_VALID;
    } else {
      this.mode = MODE.ADD_CC;
    }
  }

  handleCreditBalance = () => {
    this.creditBalance = !this.creditBalance;
  }

  render() {
    const {
      subscription,
      t,
      mobxStore: {
        country,
        accountStore: {
          localAccount,
          referralShopify,
        },
      },
    } = this.props;

    const currentCurrency = t('checkout:current-currency');
    let amount = subscription.totalValue;

    let referralCredits = localAccount
      && localAccount.accountData
      && Number(localAccount.accountData
        && localAccount.accountData.metrics.creditBalance);

    const ENABLE_SHOPIFY_CREDITS = false;

    if (ENABLE_SHOPIFY_CREDITS && referralShopify) {
      referralCredits = referralShopify.creditBalance;
    }

    if (this.creditBalance) {
      amount -= referralCredits;

      if (amount < 0) {
        amount = 0;
      }
    }

    const legalLink = {
      us: '/legal/terms',
      uk: '/legal/terms',
      ch: 'https://discover.plume.com/sunrise/legal?tabId=germantermsofsale',
    };
    return (
      <>
        {this.errorData.error && (
          <ErrorOverlay data={this.errorData} onClose={this.onErrorDismiss} />
        )}
        <div
          className={cx({
            [css['renew-container']]: true,
            [css['is-validating']]: this.isValidating && this.state.activeTab !== 'sepa',
          })}
        >
          {(this.isValidating && this.state.activeTab !== 'sepa' && (
            <Spinner className={cx({
              spinner: true,
              [css['overlay-spinner']]: true,
            })}
            />
          ))}
          <div className={cx(css['renew-info'], css['renew-info-highlight'])}>
            <p
              className={cx('default-m', 'regular')}
            >
              {subscription.currentTerm !== 12 && subscription.currentTerm !== 24 ? (
                t('membership:memberships.renew-modal.costMonthly', { amount: currentCurrency + amount })
              ) : (
                t('membership:memberships.renew-modal.cost', { amount: currentCurrency + amount })
              )}
            </p>
            {
              referralCredits > 0 && (
                <p style={{ marginTop: 10 }}>
                  <label htmlFor="cba">
                    <input name="cba" type="checkbox" checked={this.creditBalance} onClick={this.handleCreditBalance} />
                    Apply credit balance
                  </label>
                </p>
              )
            }
          </div>
          <div className={css['payment-methods-wrapper']}>
            { this.renderPaymentMethodWidget(this.mode, country) }
          </div>
          {this.paymentCardCount > 0 && (
            <a
              onClick={this.toggleMode}
              className={css['add-new-method']}
            >
              {this.mode === MODE.CVV_VALID
                ? t('membership:memberships.renew-set-up-modal.add-payment')
                : t('membership:memberships.renew-set-up-modal.select-payment')
              }
            </a>
          )}
          <div className={cx('form-row', css['check-terms'])}>
            <div className={css['input-checkbox']}>
              <label htmlFor="agree-to-terms">
                <input type="checkbox" id="agree-to-terms" />
                <span
                  onClick={this.handleAgreeToTermsClick}
                  className={cx({
                    [css['input-checkbox-checkmark']]: true,
                    [css['input-checkbox-checkmark-active']]: this
                      .agreeToTerms,
                  })}
                >
                  {this.agreeToTerms && (
                    <IconCheckmark
                      className={css.checkmarkIcon}
                      fillColor="#6269ff"
                    />
                  )}
                </span>
                <span className={css['input-checkbox-label']}>
                  <Trans
                    i18nKey="membership:memberships.renew-set-up-modal.acknowledge"
                    components={[
                      <a href={legalLink[country]} target="_blank">Terms of Service</a>, // eslint-disable-line react/jsx-no-target-blank
                      <a href={legalLink[country]} target="_blank">Cancellation Policy</a>, // eslint-disable-line react/jsx-no-target-blank
                    ]}
                  />
                </span>
              </label>
            </div>
          </div>
          <div className={css['renew-button']}>
            <button
              className="btn btn-solid-dark btn-medium"
              type="button"
              disabled={this.submitDisabled}
              onClick={() => this.handleSubmit()}
            >
              {t('membership:memberships.renew-modal.button')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default ImmediateRenewModal;
