import React, { Component } from 'react';
import { withTranslation } from 'server/i18n';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { CREDIT_CARDS } from 'components/common/cards';
import cx from 'classnames';
import css from './style.css';
import style from '../../dashboard-payment-credit-card/style.css';

@withTranslation('membership')
@inject('mobxStore')
@observer
class PaymentMethodsList extends Component {
    static uniqueCards = (card, index, cards) => cards
      .findIndex((element) => {
        const {
          cardType,
          expirationMonth,
          expirationYear,
          cardNumber,
        } = element;

        return (cardType === card.cardType)
                && (expirationMonth === card.expirationMonth)
                && (expirationYear === card.expirationYear)
                && (cardNumber === card.cardNumber);
      }) === index;

    static isExpired(year, month) {
      if (year && month) {
        return +new Date(year, month, 1, 0, 0, 0, 0) < +new Date();
      }
      return false;
    }

  @observable _selectedPaymentMethodIndex = null;

  @observable cvvIsValid = false;

  @observable cvvIsDirty = false;

  componentDidMount() {
    const defaultMethod = this.methods().find(method => method.defaultPaymentMethod);
    if (defaultMethod) {
      const index = this.methods().findIndex(method => method.id === defaultMethod.id);
      const isSepaPaymentMethod = this.isSepaPaymentMethod(defaultMethod);
      this.props.action(defaultMethod.id, index, '', this.cvvIsValid, isSepaPaymentMethod);
    }
  }

  setSelectedPaymentMethod(paymentMethodId, index, isSepaPaymentMethod) {
    if (this._selectedPaymentMethodIndex === index) {
      return;
    }

    this._selectedPaymentMethodIndex = index;
    this.cvvIsValid = false;
    this.cvvIsDirty = false;
    this.props.action(paymentMethodId, index, '', this.cvvIsValid, isSepaPaymentMethod);
  }

    handleOnChange = (paymentMethodId, index) => (event) => {
      this.cvvIsDirty = true;
      const cvv = event.target.value.replace(/\D/g, '');
      this.cvvIsValid = !(cvv === ''
        || cvv.length < 3
        || cvv.length > 4);

      this.props.action(paymentMethodId, index, cvv, this.cvvIsValid);
    }

    isSepaPaymentMethod(paymentMethod) {
      const {
        mobxStore: {
          country,
        },
      } = this.props;
      return !!(
        country === 'be'
      && !paymentMethod.expirationYear
      && !paymentMethod.expirationMonth
      && paymentMethod.id
      );
    }

    methods() {
      const {
        mobxStore: {
          accountStore: {
            localAccount: {
              sortedPaymentMethods: paymentMethods,
            },
          },
        },
        hideExpired,
      } = this.props;
      return hideExpired
        ? paymentMethods
          .filter(PaymentMethodsList.uniqueCards)
          .filter((card) => {
            if (!card.expirationYear && !card.expirationMonth) {
              return true;
            }
            return !PaymentMethodsList.isExpired(
              card.expirationYear,
              card.expirationMonth,
            );
          })
        : paymentMethods
          .filter(PaymentMethodsList.uniqueCards);
    }

    renderPaymentMethod(paymentMethod, index) {
      const {
        t,
        mobxStore: {
          accountStore: {
            localAccount: {
              paymentsFadedOut,
            },
          },
        },
      } = this.props;
      const {
        defaultPaymentMethod,
        expirationYear,
        ibanNumber,
        expirationMonth,
        cardType,
        cardNumber,
        id,
      } = paymentMethod;
      const selectedPaymentMethod = this._selectedPaymentMethodIndex === index
        || (this._selectedPaymentMethodIndex === null && defaultPaymentMethod);

      const isExpired = PaymentMethodsList.isExpired(
        expirationYear,
        expirationMonth,
      );

      const isSepaPaymentMethod = this.isSepaPaymentMethod(paymentMethod);

      const computedCardType = isSepaPaymentMethod ? 'sepa' : cardType;

      const cardDescription = isSepaPaymentMethod ? (
        <>
          <div className={cx(`${style.cardInfoText}`, 'accent-text-color')}>
            Domiciliation (SEPA)
          </div>
          <div className={style.cardInfoText}>
            IBAN se terminant par
            {' '}
            {ibanNumber && ibanNumber.substr(ibanNumber.length - 4)}
          </div>
        </>

      ) : (
        <div className={cx(style.cardInfoText, style.customWidth)}>
          {t('membership:payment.last-four', 'Last four')}
          &nbsp;
          { cardNumber.substr(cardNumber.length - 4) }
          <br />
          {expirationMonth}
          /
          {expirationYear}
        </div>
      );
      const cardImage = (
        <img
          className={css['card-badge']}
          src={CREDIT_CARDS[computedCardType.toLowerCase()].image}
          alt={CREDIT_CARDS[computedCardType.toLowerCase()].name}
        />
      );

      const shouldFadeOut = paymentsFadedOut
        && (this._selectedPaymentMethodIndex === index || index === 0);
      return (
        <div
          data-selected={selectedPaymentMethod}
          className={cx({
            [css.card]: true,
            [css.cardFadeOut]: shouldFadeOut,
            [css['card-active']]: selectedPaymentMethod,
          })}
          id={id}
          key={id}
          onClick={() => this.setSelectedPaymentMethod(
            id,
            index,
            isSepaPaymentMethod,
          )}
        >

          <div className={cx('default-l', 'semi-bold', css['card-text'])}>
            { cardImage }
            { cardDescription }
            <br />
            { isExpired && (
            <div className="dashboard-alert">
              {t('membership:payment.credit-card-expired', 'Credit card expired!')}
            </div>
            )}
            { isExpired && defaultPaymentMethod && (
            <div className="dashboard-alert">
                {t('membership:payment.add-payment', 'Add a valid payment method for uninterrupted service.')}
            </div>
            )}
            { selectedPaymentMethod && !isSepaPaymentMethod && (
            <div className={css['card-cvv']}>
                {t('membership:payment.cvv', 'Confirm card CVV')}
              <input
                type="text"
                ref={(input) => { this.cvvInput = input; }}
                maxLength={4}
                onChange={this.handleOnChange(id, index)}
                className={cx({
                  [css['input-error']]: !this.cvvIsValid && this.cvvIsDirty,
                  [css['input-cvv']]: true,
                })}
              />
            </div>
            )}
          </div>
        </div>
      );
    }

    render() {
      const methods = this.methods();
      return (
        <div className="dashboard-cards-payment">
          {
            methods
              .map((paymentMethod, index) => this.renderPaymentMethod(
                paymentMethod,
                index,
              ))
          }
        </div>
      );
    }
}

export default PaymentMethodsList;
