import { types } from 'mobx-state-tree';

/* const RatePlanCharge = types.model({
  id: types.string,
  name: types.string,
  pricing: types.array(types.model({
    discountAmount: types.maybeNull(types.number),
    discountPercentage: types.maybeNull(types.number),
    currency: types.string,
    price: types.maybeNull(types.number),
  })),
  productDiscountApplyDetails: types.array(types.string),
}); */

// TODO split coupon models
export default types.model('CouponData', {
  campaign: types.string,
  code: types.string,
  creditBalance: types.maybeNull(types.number),
  availableQuantity: types.maybeNull(types.number),
  initialQuantity: types.maybeNull(types.number),
}).actions(self => ({
  getDiscountMultiplier(currency, id) {
    let multiplier = 0;
    const planCharges = self.discount.ratePlan.productRatePlanCharges;

    planCharges.forEach((pc) => {
      if (
        pc.productDiscountApplyDetails[0] === 'All'
        || pc.productDiscountApplyDetails.includes(id)
      ) {
        const pricing = pc.pricing.find(x => x.currency === currency);
        if (pricing) {
          multiplier += parseFloat(pricing.discountPercentage) / 100.00;
        }
      }
    });

    return multiplier;
  },
  getFixedDiscountAmount(currency) {
    let discount = 0;
    const ratePlanCharges = self.discount.ratePlan.productRatePlanCharges;

    ratePlanCharges.forEach((ratePlanCharge) => {
      const pricing = ratePlanCharge.pricing.find(x => x.currency === currency);
      if (pricing && pricing.discountAmount !== null) {
        discount += pricing.discountAmount;
      }
    });

    return discount;
  },
}));
